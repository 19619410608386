import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQueries";

export const orderAPI = createApi({
    reducerPath: "orderAPI",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["appointments", "appointment"],
    endpoints: (build) => ({
        addOrder: build.mutation({
            query: ({ type, values }) => ({
                url: `/api/patient/order?type=${type}`,
                method: "POST",
                body: { ...values },
            }),
            providesTags: ["appointments", "appointment"],
            invalidatesTags: ["appointments"],
        }),
        addRepeatOrder: build.mutation({
            query: ({ type, values }) => ({
                url: `/api/patient/repeat-order?type=${type}`,
                method: "POST",
                body: { ...values },
            }),
            providesTags: ["appointments", "appointment"],
            invalidatesTags: ["appointments"],
        }),
        getVideoToken: build.query({
            query: ({ appointmentId }) => ({
                url: `/api/patient/appointment/${appointmentId}?action=token`,
            }),
            providesTags: ["appointment"],
        }),
        getOrder: build.query({
            query: ({ appointmentId }) => ({
                url: `/api/patient/order-item/${appointmentId}`,
            }),
            providesTags: ["appointment"],
        }),
        getOrders: build.query({
            query: () => ({
                url: `/api/patient/order-items`,
            }),
            providesTags: ["appointments"],
        }),
        getOrderPrescription: build.query({
            query: ({ orderId }) => ({
                url: `/api/patient/document/${orderId}?type=prescription`,
            }),
        }),
        getOrderPrescriptionFormulary: build.query({
            query: ({ formularyId }) => ({
                url: `/api/patient/formulary/${formularyId}`,
            }),
        }),
        getOrderInvoice: build.query({
            query: ({ orderId }) => ({
                url: `/api/patient/document/${orderId}?type=invoice`,
            }),
        }),
        addOrderNotes: build.mutation({
            query: ({ orderId, values }) => ({
                url: `/api/patient/order/${orderId}/order-notes/`,
                method: "POST",
                body: { ...values },
            }),
            invalidatesTags: ["appointment"],
        }),
        updateOrderNote: build.mutation({
            query: ({ orderNoteId, values }) => ({
                url: `/api/patient/order-note/${orderNoteId}`,
                method: "PUT",
                body: { ...values },
            }),
            invalidatesTags: ["appointments", "appointment"],
        }),
        cancelOrder: build.mutation({
            query: ({ orderId, values }) => ({
                url: `/api/patient/order-item/${orderId}?action=cancel`,
                method: "PUT",
                body: { ...values },
            }),
            invalidatesTags: ["appointment"],
        }),
        getVoucherValidity: build.query({
            query: ({ voucherId }) => ({
                url: `/api/patient/coupon/${voucherId}`,
            }),
        }),
        getAppointments: build.query({
            query: ({ page = 0, pageSize = 320, type = "full" }) => ({
                url: `/api/public/appointments?type=${type}&page=${page}&size=${pageSize}&sort=datetime,asc`,
            }),
            providesTags: ["appointments"],
        }),
        addOrderPreferences: build.mutation({
            query: ({ values }) => ({
                url: `/api/patient/order-preferences`,
                method: "POST",
                body: { ...values },
            }),
            providesTags: ["appointments", "appointment"],
            invalidatesTags: ["appointments"],
        }),
        getAccessToken: build.query({
            query: () => ({
                url: `/api/patient/word-press/token-generator`,
            }),
        }),
        getPreferences: build.query({
            query: ({preferenceToken, accessToken}) => ({
                url: `https://treat-it.clinic/wp-json/patient/v1/get-orders/?token=${accessToken}`,
                headers: {
                    Authorization: `Bearer ${preferenceToken}`,
                },
            }),
        }),
    }),
});

export const {
    useAddOrderMutation,
    useAddRepeatOrderMutation,
    useGetVideoTokenQuery,
    useGetOrderQuery,
    useGetOrdersQuery,
    useGetOrderPrescriptionQuery,
    useLazyGetOrderPrescriptionQuery,
    useLazyGetOrderPrescriptionFormularyQuery,
    useGetOrderInvoiceQuery,
    useAddOrderNotesMutation,
    useUpdateOrderNoteMutation,
    useCancelOrderMutation,
    useLazyGetVoucherValidityQuery,
    useGetAppointmentsQuery,
    useAddOrderPreferencesMutation,
    useGetAccessTokenQuery,
    useLazyGetPreferencesQuery,
} = orderAPI;
