import React from "react";
import Collapsible from "../../components/collapsible/Collapsible";
import "../../pages/appointment/appointment-page.scss";

const PreferenceSummary = ({ preferences = [] }) => {
    const sortedPreferences = [...preferences].sort((a, b) => Number(a.priority) - Number(b.priority));

    return (
        <div className="row mx-0 mb-5 mb-md-0 pb-md-0">
            <div className="col-12 col-lg-12 col-xl-11 col-xxl-10 py-4 px-4 pb-4 pb-md-3 mb-0 mb-md-5 bg-gray-card border-custom rounded">
                <Collapsible title="Preference Summary" expanded>
                    <div className="row">
                        {sortedPreferences?.map((preference) => (
                            <div key={preference.sku} className="col-12 d-flex align-items-center mt-3 pb-1">
                                <div className="me-3">
                                    <img
                                        src={preference?.imageURL}
                                        alt={preference.name}
                                        className="img-fluid"
                                        style={{ maxWidth: "60px" }}
                                    />
                                </div>
                                <div className="flex-grow-1">
                                    <p className="mb-0 text-primary">{preference.name}</p>
                                </div>
                                <div className="bg-light-blue text-dark-blue text-nowrap px-2 ms-1 rounded-custom">
                                    Preference {preference.priority}
                                </div>
                            </div>
                        ))}
                    </div>
                </Collapsible>
            </div>
        </div>
    );
}

export default PreferenceSummary