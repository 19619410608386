import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppointmentContext } from "../../../providers/AppointmentProvider";
import { RepeatOrderContext } from "../../../providers/RepeatOrderProvider";

import GoBack from "../../../components/GoBack";
import TextArea from "../../../components/forms/TextArea";
import {
    Form,
    SearchableSelect,
    SubmitButton,
} from "../../../components/forms";
import PrevPrescription from "../../../components/prevPrescription/PrevPrescription";

const CHANGE_REASONS = [
    {
        label: "I want to try a different strain or brand",
        value: "I want to try a different strain or brand",
    },
    {
        label: "I want to try a different administration route (vape/flower/oil/etc)",
        value: "I want to try a different administration route (vape/flower/oil/etc)",
    },
    {
        label: "I want to add or remove an item from my prescription",
        value: "I want to add or remove an item from my prescription",
    },
    {
        label: "I’m experiencing significant side effects",
        value: "I’m experiencing significant side effects",
    },
    {
        label: "My needs aren’t sufficiently met",
        value: "My needs aren’t sufficiently met",
    },
    {
        label: "It’s something else",
        value: "It’s something else",
    },
    {
        label: "Other - Book a Repeat Consultation",
        value: "Other - Book a Repeat Consultation",
    },
];

export default function ReOrderFeedBackPage() {
    const navigate = useNavigate();
    const { setAppointmentData, appointmentData } =
        useContext(AppointmentContext);
    const { parentOrderId } = useContext(RepeatOrderContext);

    const [selectedReason, setSelectedReason] = useState();
    const [initValues, setInitValues] = useState({
        reason: "",
        tellUsMore: "",
    });
    const token = localStorage.getItem('AccessToken' || '');

    const handleSubmit = (values) => {
        const prescriptionRejectReason = {
            ...values,
        };

        let tempAppointmentData = { ...appointmentData };

        if (tempAppointmentData.pictures) {
            delete tempAppointmentData?.pictures;
        }

        const data = {
            ...tempAppointmentData,
            prescriptionRejectReason,
        };

        setAppointmentData(data);

        window.location.href = `https://treat-it.clinic/shop?orderid=${parentOrderId}&token=${token}`;
    };

    useEffect(() => {
        if (appointmentData?.prescriptionRejectReason) {
            setInitValues({
                ...appointmentData?.prescriptionRejectReason,
            });
        }
    }, [appointmentData]);

    return (
        <div className="row mx-0 pt-5 mb-12">
            <div className="col-12 col-lg-8 col-xl-6 mx-auto mb-12">
                <GoBack center="justify-content-center" />
                <h2 className="text-center fw-bold mt-5">
                    REQUEST A PRESCRIPTION CHANGE
                </h2>
                <div className="px-6 mb-8">
                    <p className="body body-small text-dark-2 mb-3 text-center">
                        To request a change, please select one of the menu items below. 
                        Use the text field to provide as much detail as possible. Upon submitting your feedback, 
                        you will be redirected to the Treat It Shop.
                        {/*You may receive a few follow-up questions from*/}
                        {/*your doctor. * Please be aware the doctor may not be*/}
                        {/*able to approve the requested change, and may request*/}
                        {/*that you book another online consultation.*/}
                    </p>
                </div>
                <div className="d-flex justify-content-center mt-5">
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={initValues}
                        enableReinitialize
                    >
                        <div className="col-11 col-sm-10 mx-auto">
                            <div className="d-flex flex-column gap-1 mb-2">
                                <span>Reason to request a prescription<span className="text-red">*</span></span>
                                <SearchableSelect
                                    name="reason"
                                    defaultSelectPlaceholder="Select a reason"
                                    containerClass="w-100 mb-4"
                                    options={CHANGE_REASONS}
                                    onSelect={setSelectedReason}
                                    selectedValues={selectedReason}
                                    searchable={false}
                                    multiSelect={false}
                                    fullWidth
                                    hideIcon
                                />
                            </div>
                            <div className="d-flex flex-column gap-1 mb-4">
                                <span>Tell us more</span>
                                <TextArea
                                    name="tellUsMore"
                                    placeholder="Enter your message"
                                />
                            </div>
                        </div>
                        <div className="col-11 col-sm-5 mx-auto mt-6 mb-4">
                            <SubmitButton disabled={!selectedReason}> Submit & Continue</SubmitButton>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
