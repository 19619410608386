import { useMemo } from "react";

import { useGetOrderPrescriptionQuery } from "../../services/orderService";

import Price from "../price/Price";
import BasicTable from "../tables/BasicTable";

import { ORDER_STATUS } from "../../constants/orderStatus";

export default function PrevPrescription({ orderId }) {
    const { data: prescriptionData } = useGetOrderPrescriptionQuery(
        { orderId },
        { skip: !orderId }
    );

    const tableData = useMemo(() => {
        if (prescriptionData) {
            if (
                prescriptionData.status === ORDER_STATUS.DELIVERED ||
                // temp
                prescriptionData.status === ORDER_STATUS.DISPENSED_BY_PHARMACY
            ) {
                const medication = prescriptionData.medication.map((med) => ({
                    ...med,
                    formulary: {
                        ...med.formulary,
                        pharmacyName: prescriptionData.pharmacy.displayName,
                    },
                }));

                return medication;
            }
        }

        return [];
    }, [prescriptionData]);

    return (
        <>
            <p className="body body-small fw-semibold text-center text-primary">
                Your previous order details <i>#{orderId}</i>
            </p>
            <div className="pe-2 text-start">
                <BasicTable
                    data={tableData}
                    columns={[
                        {
                            header: "Medication Name",
                            accessor: "formulary.productName",
                        },
                        {
                            header: "Pharmacy",
                            accessor: "formulary.pharmacyName",
                        },
                        {
                            header: "Admin Route",
                            accessor: "formulary.administrationRoute",
                        },
                        {
                            header: "Med. Profile",
                            accessor: "formulary.medicineProfile",
                        },
                        {
                            header: "Strain",
                            accessor: "formulary.strain",
                        },
                        {
                            header: "Quantity",
                            accessor: "quantity",
                        },
                        {
                            header: "Price",
                            accessor: "charge",
                            className: "text-end",
                            Cell: ({ cell: { value } }) => (
                                <Price amount={value} currency="GBP" />
                            ),
                        },
                    ]}
                    variant="px-0"
                    isSortable={true}
                    isPaginated={false}
                    pageSize={1}
                    currentPage={0}
                    treatmentMedication={{
                        active: true,
                        info: prescriptionData?.medication.map(
                            (prescription) => ({
                                medication: prescription.formulary.productName,
                            })
                        ),
                    }}
                />
            </div>
        </>
    );
}
