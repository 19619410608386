import React, { useEffect } from "react";

import {
    SubmitButton,
    TextField,
    YesNoRadio,
    Slider,
    Radio
} from "../../../../components/forms";
import { useFormikContext } from "formik";
import { SIDE_EFFECT_FORM, PEG_FORM, GAD_FORM } from "../../../../constants/orderNotesQuestions";

import "./questionnaire-form.scss"

export default function QuestionnaireForm({ questionnaire, isMentalHealthQuestionnaire:mhQuestionnaire }) {
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
        const average =
            (values.painInPastWeek +
                values.painInterferenceWithEnjoyment +
                values.painInterferenceWithGeneralActivity) /
            3;
        const { painInPastWeek, painInterferenceWithEnjoyment, painInterferenceWithGeneralActivity } = values;

        const data = {
            type: "PEG_FORM",
            questionAnswer: JSON.stringify({
                painInPastWeek,
                painInterferenceWithEnjoyment,
                painInterferenceWithGeneralActivity,
                average,
            }),
        };

        handlePEGFormSubmit(data);
    }, [values.painInPastWeek, values.painInterferenceWithEnjoyment, values.painInterferenceWithGeneralActivity]);

    useEffect(() => {
        const score = values.feeling + values.worrying;
        const { feeling, worrying } = values;

        const data = {
            type: "GAD_FORM",
            questionAnswer: JSON.stringify({ feeling, worrying, score }),
        };

        handleGADFormSubmit(data);
    }, [values.feeling, values.worrying]);

    useEffect(() => {
        const average = values.sideEffectPain / 1;
        const { sideEffectPain } = values;

        const data = {
            type: "SIDE_EFFECT_FORM",
            questionAnswer: JSON.stringify({ sideEffectPain, average }),
        };

        handleSideEffectFormSubmit(data);
    }, [values.sideEffectPain]);

    const handlePEGFormSubmit = (values) => {
        setFieldValue("q_7.answer", values);
    };

    const handleGADFormSubmit = (values) => {
        setFieldValue("q_10.answer", values);
    };

    const handleSideEffectFormSubmit = (values) => {
        setFieldValue("q_6.answer", values);
    };

    const isComplete =
        values.q_1?.answer !== "" &&
        values.q_2?.answer !== "" &&
        values.q_3?.answer !== "" &&
        values.q_4?.answer !== "" &&
        values.q_5?.answer !== "" &&
        ((values.q_5?.answer === true && values.q_6?.answer !== "") && (values.q_5?.answer === false && values.q_6?.answer === "")) &&
        values.q_7?.answer !== "";

    const isMentalHealthComplete =
        values.q_1?.answer !== "" &&
        values.q_2?.answer !== "" &&
        values.q_3?.answer !== "" &&
        values.q_4?.answer !== "" &&
        values.q_5?.answer !== "" &&
        ((values.q_5?.answer === true && values.q_6?.answer !== "") || (values.q_5?.answer === false && values.q_6?.answer === "")) &&
        values.q_7?.answer !== "" &&
        values.q_8?.answer !== "" &&
        values.q_9?.answer !== "" &&
        values.q_10?.answer !== "" &&
        values.q_11?.answer !== "";

    if (values.q_5?.answer === false && values.q_6?.answer !== "") {
        setFieldValue("q_6.answer", '');
    }

    return (
        <div className="border rounded-custom px-3">
            <table className="table table-responsive custom-table px-0 mb-0">
                <tbody>
                    {questionnaire.map((question, idx) => {
                        const renderQuestion = () => (
                            <React.Fragment key={idx}>
                                {question.name === "q_6" && values.q_5?.answer === true && (
                                    <tr>
                                        <td className="pt-3 pb-0 ps-2 border-0">
                                            <label className={`fw-semibold mb-0`}>Side effect Form</label>
                                        </td>
                                    </tr>
                                )}
                                {question.type === "modal" && (
                                    <tr>
                                        <td className="pt-3 pb-0 ps-2 border-0">
                                            <label className={`fw-semibold mb-0`}>PEG form</label>
                                        </td>
                                    </tr>
                                )}
                                {question.type === "gad_modal" && (
                                    <tr>
                                        <td className="pt-3 pb-0 ps-2 border-0">
                                            <label className={`fw-semibold mb-0`}>GAD form</label>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    {question.type !== "modal" && question.type !== "gad_modal" && (
                                        <td className="pt-3 pb-0 ps-2 border-0 d-block d-sm-table-cell">
                                            <span>{question.text}<span className="text-red">*</span></span>
                                        </td>
                                    )}
                                    {question.type === "choice" && (
                                        <td className="pt-3 pb-0 border-0 d-block d-sm-table-cell">
                                            <YesNoRadio name={`${question.name}.answer`} space={2} reorder={true}/>
                                        </td>
                                    )}
                                </tr>
                                {question.name === "q_6" && values.q_5?.answer === true && (
                                    <tr>
                                        <td colspan="2" className="pt-3 pb-0 ps-2 ps-sm-5">
                                            <div className="my-auto">
                                                <div className="fw-light">
                                                    {SIDE_EFFECT_FORM.keys.map((key) => (
                                                        <div className="row mx-0 mb-3" key={key}>
                                                            <Slider
                                                                name={key}
                                                                min={0}
                                                                max={10}
                                                                step={0.1}
                                                                marks={[0, 10]}
                                                                labelClass="fw-semibold"
                                                                disabled={false}
                                                            />
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    marginTop: "15px",
                                                                    paddingLeft: "5px",
                                                                    paddingRight: "16px",
                                                                }}
                                                            >
                                                                {key === "sideEffectPain" && (
                                                                    <>
                                                                        <span>0 - No side effects</span>
                                                                        <span>10 - Intolerable SE's</span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                {question.type === "modal" &&
                                    PEG_FORM.keys.map((key) => (
                                        <>
                                            <tr>
                                                <td colspan="2" className="pt-3 pb-0 ps-2 border-0">
                                                    <span>{PEG_FORM.data[key]}<span className="text-red">*</span></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="pt-3 pb-0 ps-2">
                                                    <div className="row mx-0 mb-3" key={key}>
                                                        <Slider
                                                            name={key}
                                                            min={0}
                                                            max={10}
                                                            step={0.1}
                                                            marks={[0, 10]}
                                                            labelClass="fw-semibold"
                                                            disabled={false}
                                                        />
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                marginTop: "15px",
                                                                paddingLeft: "5px",
                                                                paddingRight: "16px",
                                                            }}
                                                        >
                                                            {key === "painInPastWeek" ? (
                                                                <>
                                                                    <span>0 - No pain</span>
                                                                    <span>10 - Pain as bad as you can imagine</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span>0 - Does not interfere</span>
                                                                    <span>10 - Completely interferes</span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                {question.type === "gad_modal" &&
                                    GAD_FORM.keys &&
                                    GAD_FORM.keys.map((key) => (
                                        <>
                                            <tr>
                                                <td colspan="2" className="pt-0 pb-0 ps-2 border-0">
                                                    <span>{PEG_FORM.data[key]}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" className="pt-3 pb-0 ps-2">
                                                    <div className="row mx-0 mb-3" key={key}>
                                                        <label className="form-label p-0">
                                                            {GAD_FORM.data[key].label}<span className="text-red">*</span>
                                                        </label>
                                                        <div className="d-flex flex-column flex-lg-row px-0">
                                                            {GAD_FORM.data[key].values.map((data, idx) => (
                                                                <div className="me-0 me-lg-3 mb-2 mb-lg-0" key={idx}>
                                                                    <Radio
                                                                        name={key}
                                                                        id={idx}
                                                                        label={data}
                                                                        disabled={false}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                <tr className="p-0">
                                    <td
                                        colSpan={2}
                                        className={`pt-0 ${
                                            question.type === "gad_modal" || question.type === "modal" ? "pb-0" : "pb-3"
                                        } px-2 px-sm-5 ${
                                            (idx === questionnaire.length - 1 ||
                                                (question.name === "q_6" && values.q_5?.answer === true) ||
                                                question.type === "gad_modal" ||
                                                question.type === "modal") &&
                                            "border-0"
                                        }`}
                                    >
                                        {values.q_1?.answer === false && question.name === "q_1" && (
                                            <div className="pt-2">
                                                <TextField
                                                    name={`${question.name}.reason`}
                                                    placeholder="Tell us more"
                                                />
                                            </div>
                                        )}
                                        {((values.q_2?.answer === true && question.name === "q_2") ||
                                            (values.q_3?.answer === true && question.name === "q_3") ||
                                            (values.q_4?.answer === true && question.name === "q_4") ||
                                            (values.q_5?.answer === true && question.name === "q_5") ||
                                            (values.q_8?.answer === true && question.name === "q_8") ||
                                            (mhQuestionnaire &&
                                                values.q_9?.answer === true &&
                                                question.name === "q_9") ||
                                            (mhQuestionnaire &&
                                                values.q_11?.answer === true &&
                                                question.name === "q_11")) && (
                                            <div className="pt-2">
                                                <TextField
                                                    name={`${question.name}.reason`}
                                                    placeholder="Tell us more"
                                                />
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            </React.Fragment>
                        );

                        if (question.name !== "q_6") {
                            return renderQuestion();
                        } else if (question.name === "q_6" && values.q_5?.answer === true) {
                            return renderQuestion();
                        } else {
                            return null;
                        }
                    })}


                </tbody>
            </table>
            <div className="row mx-0 mt-6 mb-4">
                <div className="col-12 col-sm-6 mx-auto px-0">
                    <SubmitButton
                        disabled={(!mhQuestionnaire && !isComplete) || (mhQuestionnaire && !isMentalHealthComplete)}>
                        Submit feedback
                    </SubmitButton>
                </div>
            </div>
        </div>
    );
}
